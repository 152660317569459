import { Routes, Route } from 'react-router-dom';
import HomePage from '../pages/HomePage';
import Rsvp from '../pages/Rsvp';
import Secret from '../pages/Secret';

const MainRouter = () => {
    return (
        <Routes>
            <Route path='/' element={<HomePage />} />
            <Route path='/rsvp' element={<Rsvp />}></Route>
            <Route path='/secret-link/*' element={<Secret />}></Route>
        </Routes>
    );
};

export default MainRouter;
