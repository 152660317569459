import FirstRow from '../components/homepage/FirstRow';
import SecondRow from '../components/homepage/SecondRow';
import ThirdRow from '../components/homepage/ThirdRow';

const HomePage = () => {
    return (
        <>
            <FirstRow />
            <SecondRow />
            <ThirdRow />
        </>
    );
};

export default HomePage;
