import { HTManageTable, HTTitleBar } from '@hanssens/ht-gui';

const Sleepers = ({ attendees }) => {
    const sleepers = attendees.filter((a) => a.staysOverForBreakfast);

    const names = sleepers.map((s) => {
        return { name: s.name, amount: s.willAttend && s.partnerWillAttend ? '2-persoons kamer' : '1-persoon kamer' };
    });

    const columns = {
        name: {
            label: 'Naam',
        },
        amount: {
            label: 'Type kamer',
        },
    };

    const sleepersData = [{ name: 'Aantal', value: sleepers.length }];
    const sleepersDataColumns = { name: { label: 'Naam' }, value: { label: '' } };

    return (
        <>
            <HTTitleBar label='Overzicht' additionalRowClasses='mt-2' colour='bg-orange' />
            <HTManageTable
                data={sleepersData}
                columns={sleepersDataColumns}
                search={{
                    type: 'hidden',
                }}
            />
            <HTTitleBar label='Slapers' additionalRowClasses='mt-2' colour='bg-orange' />
            <HTManageTable data={names} columns={columns} />
        </>
    );
};

export default Sleepers;
