const BeThere = ({ onRsvpClicked }) => {
    return (
        <div className='arne-col-3 clickable first-row mb-2 mb-md-0' onClick={onRsvpClicked}>
            <div className='bg-purple d-flex align-items-center fs-1 px-4 position-relative h-100  font-weight-bold'>
                <span className='d-flex flex-center text-white pb-4'>Wij trouwen en jij kan er bij zijn</span>
                <div className='linup-fixed fs-4 p-3 text-white'>Line-up volledig gekend</div>
            </div>
        </div>
    );
};

export default BeThere;
