import { useLocation } from 'react-router-dom';
import NavigationBarItem from './NavigationBarItem';

const NavigationBar = () => {
    const location = useLocation();

    return (
        <div className='bg-black'>
            <div className='container'>
                <div className='row'>
                    <div className='col'>
                        <div className='d-flex flex-row'>
                            <NavigationBarItem name='home' link='/' active={location.pathname === '/'} />
                            <NavigationBarItem name='rsvp' link='/rsvp' active={location.pathname === '/rsvp'} />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NavigationBar;
