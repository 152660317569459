import { Link } from 'react-router-dom';

const NavigationBarItem = ({ name, link, active }) => {
    let className = 'd-flex px-3 text-white navbar-item text-uppercase align-items-center fs-2 clickable font-weight-bold';

    if (active) {
        className += ' active';
    }

    return (
        <div id={name} className={className}>
            <Link to={link} className='text-white text-decoration-none'>
                {name}
            </Link>
        </div>
    );
};

export default NavigationBarItem;
