const Artist = ({ name, func, info, color, icon }) => {
    return (
        <div className='col-12 col-md-4 overflow-hidden position-relative second-row mb-2'>
            <div className={color + ' h-100 w-100'}>
                <div className='artist-icon w-100 d-flex justify-content-center align-items-end'>
                    <i className={'fa-thin text-white fs-a-2 ' + icon} />
                </div>

                <div className='artist-name text-white fs-5 font-weight-bold text-uppercase'>{name}</div>
                <div className='artist-function text-white font-weight-bold'>{func}</div>
                <div className='artist-info text-white font-weight-bold text-uppercase bg-purple p-2'>{info}</div>
            </div>
        </div>
    );
};

export default Artist;
