import BeThere from './BeThere';

import FanjaArne1 from '../../assets/images/arne-fanja-1.png';
import LetUsKnow from './LetUsKnow';
import { useNavigate } from 'react-router-dom';

const FirstRow = () => {
    const navigate = useNavigate();

    const handleRsvpClicked = (e) => {
        e.preventDefault();
        navigate('/rsvp');
    };

    return (
        <div className='row mt-2'>
            <BeThere onRsvpClicked={handleRsvpClicked} />
            <div className='arne-col-2 first-row object-fit mb-2 mb-md-0'>
                <div className='h-100 w-100' style={{ backgroundImage: 'url(' + FanjaArne1 + ')', backgroundSize: 'cover', backgroundPosition: '50% 50%' }}></div>
            </div>
            <LetUsKnow onRsvpClicked={handleRsvpClicked} />
            <div className='arne-col first-row d-none d-md-block'>
                <div className='bg-orange-light w-100 h-100'></div>
            </div>
        </div>
    );
};

export default FirstRow;
