import InfoElement from './InfoElement';
import InfoElement2 from './InfoElement2';

const SecondRow = () => {
    return (
        <div className='row mt-2'>
            <InfoElement name='Deurmoment' icon='fa-door-open' time='10:30' color='bg-blue' />
            <InfoElement name='Burgerlijke trouw' icon='fa-gavel' time='11:00' color='bg-green' />
            <InfoElement name='Ontvangst genodigden' icon='fa-handshake-simple' time='16:15' color='bg-green-light' />
            <InfoElement name='Ceremonie' icon='fa-rings-wedding' time='16:30' color='bg-purple' />
            <InfoElement name='Receptie' icon='fa-champagne-glasses' time='18:00' color='bg-orange' />
            <InfoElement name='Diner' icon='fa-utensils' time='19:30' color='bg-blue' />
            <InfoElement2 name='Feest' icon='fa-face-party' time='23:30' color='bg-green' />
        </div>
    );
};

export default SecondRow;
