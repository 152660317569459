import { Route, Routes } from 'react-router-dom';

import Summary from '../components/secret-link/Summary';
import Sleepers from '../components/secret-link/Sleepers';
import Party from '../components/secret-link/Party';
import Ceremony from '../components/secret-link/Ceremony';
import CivilFidelity from '../components/secret-link/CivilFidelity';

const SecretRouter = ({ attendees }) => {
    return (
        <Routes>
            <Route path='sleepers' element={<Sleepers attendees={attendees} />} />
            <Route path='party' element={<Party attendees={attendees} />} />
            <Route path='ceremony' element={<Ceremony attendees={attendees} />} />
            <Route path='civil-fidelity' element={<CivilFidelity attendees={attendees} />} />
            <Route path='*' element={<Summary attendees={attendees} />} />
        </Routes>
    );
};

export default SecretRouter;
