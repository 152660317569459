import { ResizeDetector } from '@hanssens/ht-resize';
import { HTSection } from '@hanssens/ht-gui';

import NavigationBar from '../components/layout/NavigationBar';
import MainRouter from '../routers/MainRouter';

import HeaderImage from '../assets/images/header.png';

function App() {
    return (
        <div className='App h-100'>
            <header className='app-header d-flex justify-content-center overflow-hidden'>
                <div className='h-100 w-100' style={{ backgroundImage: 'url(' + HeaderImage + ')', backgroundSize: 'cover' }}></div>
            </header>
            <nav>
                <NavigationBar />
            </nav>
            <div className='container container-height'>
                <ResizeDetector />
                <MainRouter />
                <HTSection id='main-section'></HTSection>
            </div>
        </div>
    );
}

export default App;
