import { HTSelectButton } from '@hanssens/ht-gui';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import AttendeeApi from '../apis/AttendeeApi';
import SecretRouter from '../routers/SecretRouter';

const Secret = () => {
    const [attendees, setAttendees] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        AttendeeApi.get('api/feat/attendees').then((resp) => {
            setAttendees(resp.data);
        });
    }, []);

    const handleLinkClicked = (link) => {
        navigate(link);
    };

    let overViewIsActive = false;
    let ceremonyIsActive = false;
    let partyIsActive = false;
    let sleepersIsActive = false;
    let civilFidelityIsActive = false;

    switch (location.pathname) {
        case '/secret-link':
            overViewIsActive = true;
            break;
        case '/secret-link/sleepers':
            sleepersIsActive = true;
            break;
        case '/secret-link/party':
            partyIsActive = true;
            break;
        case '/secret-link/ceremony':
            ceremonyIsActive = true;
            break;
        case '/secret-link/civil-fidelity':
            civilFidelityIsActive = true;
            break;
        default:
            break;
    }

    return (
        <>
            <div className='row'>
                <div className='col-12 col-md-3 my-2'>
                    <HTSelectButton
                        label='Overzicht'
                        icon='fa-thin fa-frame'
                        active={overViewIsActive}
                        onClick={() => {
                            handleLinkClicked('/secret-link');
                        }}
                    />
                </div>
                <div className='col-12 col-md-3 my-2'>
                    <HTSelectButton
                        label='Burgerlijke trouw'
                        icon='fa-thin fa-gavel'
                        active={civilFidelityIsActive}
                        onClick={() => {
                            handleLinkClicked('/secret-link/civil-fidelity');
                        }}
                    />
                </div>
                <div className='col-12 col-md-3 my-2'>
                    <HTSelectButton
                        label='Ceremonie'
                        icon='fa-thin fa-rings-wedding'
                        active={ceremonyIsActive}
                        colour='Secondary'
                        onClick={() => {
                            handleLinkClicked('/secret-link/ceremony');
                        }}
                    />
                </div>
                <div className='col-12 col-md-3 my-2'>
                    <HTSelectButton
                        label='Eten + dansfeest'
                        icon='fa-thin fa-face-party'
                        active={partyIsActive}
                        colour='Info'
                        onClick={() => {
                            handleLinkClicked('/secret-link/party');
                        }}
                    />
                </div>
                <div className='col-12 col-md-3 my-2'>
                    <HTSelectButton
                        label='Slapers'
                        icon='fa-thin fa-bed'
                        active={sleepersIsActive}
                        colour='Orange'
                        onClick={() => {
                            handleLinkClicked('/secret-link/sleepers');
                        }}
                    />
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <SecretRouter attendees={attendees} />
                </div>
            </div>
        </>
    );
};

export default Secret;
