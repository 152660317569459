import axios from 'axios';

import * as config from '../configs/general';

const instance = axios.create({
    baseURL: config.APP_BASE_URI,
});

instance.CancelToken = axios.CancelToken;
instance.isCancel = axios.isCancel;

export default instance;
