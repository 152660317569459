import { HTManageTable, HTTitleBar } from '@hanssens/ht-gui';

const CivilFidelity = ({ attendees }) => {
    const names = attendees.reduce((acc, cur) => {
        if (cur.willAttend && cur.willAttendCivilFidelity) {
            acc.push({ name: cur.name });
        }
        if (cur.partnerWillAttend && cur.partnerWillAttendCivilFidelity) {
            acc.push({ name: cur.partnerName });
        }

        return acc;
    }, []);

    const columns = {
        name: {
            label: 'Naam',
        },
    };

    const attendeeData = [{ name: 'Aantal', value: names.length }];
    const attendeeDataColumns = { name: { label: 'Naam' }, value: { label: '' } };

    return (
        <>
            <HTTitleBar label='Overzicht' additionalRowClasses='mt-2' colour='bg-success' />
            <HTManageTable
                data={attendeeData}
                columns={attendeeDataColumns}
                search={{
                    type: 'hidden',
                }}
            />
            <HTTitleBar label='Aanwezigen burgerlijke trouw' additionalRowClasses='mt-2' colour='bg-success' />
            <HTManageTable data={names} columns={columns} />
        </>
    );
};

export default CivilFidelity;
