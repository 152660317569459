import { HTManageTable, HTTitleBar } from '@hanssens/ht-gui';

const Summary = ({ attendees }) => {
    const willAttendAttendees = attendees.filter((a) => a.lastUpdatedTime !== null && a.willAttend);
    const wontAttendAttendees = attendees.filter((a) => a.lastUpdatedTime !== null && !a.willAttend);
    const notRespondedYet = attendees.filter((a) => a.lastUpdatedTime === null);

    const summary = {
        attendees: 0,
        doorMoment: 0,
        civilFidelity: 0,
        ceremony: 0,
        reception: 0,
        dinner: 0,
        party: 0,
    };

    willAttendAttendees.forEach((wa) => {
        summary.attendees += wa.willAttend ? 1 : 0;
        summary.attendees += wa.partnerWillAttend ? 1 : 0;

        summary.doorMoment += wa.willAttendDoorMoment ? 1 : 0;
        summary.doorMoment += wa.partnerWillAttend && wa.partnerWillAttendDoorMoment ? 1 : 0;

        summary.civilFidelity += wa.willAttendCivilFidelity ? 1 : 0;
        summary.civilFidelity += wa.partnerWillAttend && wa.partnerWillAttendCivilFidelity ? 1 : 0;

        summary.ceremony += wa.willAttendCeremony ? 1 : 0;
        summary.ceremony += wa.partnerWillAttend && wa.partnerWillAttendCeremony ? 1 : 0;

        summary.reception += wa.willAttendReception ? 1 : 0;
        summary.reception += wa.partnerWillAttend && wa.partnerWillAttendReception ? 1 : 0;

        summary.dinner += wa.willAttendDinner ? 1 : 0;
        summary.dinner += wa.partnerWillAttend && wa.partnerWillAttendDinner ? 1 : 0;

        summary.party += wa.willAttendParty ? 1 : 0;
        summary.party += wa.partnerWillAttend && wa.partnerWillAttendParty ? 1 : 0;
    });

    const summaryTableData = [
        {
            name: 'Aanwezigen',
            amount: summary.attendees,
        },
        {
            name: 'Deurmoment',
            amount: summary.doorMoment,
        },
        {
            name: 'Burgerlijke trouw',
            amount: summary.civilFidelity,
        },
        {
            name: 'Ceremonie',
            amount: summary.ceremony,
        },
        {
            name: 'Receptie',
            amount: summary.reception,
        },
        {
            name: 'Diner',
            amount: summary.dinner,
        },
        {
            name: 'Avondfeest',
            amount: summary.party,
        },
    ];

    const summaryColumns = {
        name: {
            label: 'Naam',
        },
        amount: {
            label: 'Aantal',
        },
    };

    const columns = {
        name: {
            label: 'Naam',
        },
        willAttendDoorMoment: {
            label: 'Deurmoment',
        },
        willAttendCivilFidelity: {
            label: 'Burgerlijke trouw',
        },
        willAttendCeremony: {
            label: 'Ceremonie',
        },
        willAttendReception: {
            label: 'Receptie',
        },
        willAttendDinner: {
            label: 'Diner',
        },
        willAttendParty: {
            label: 'Party',
        },
        specialFoodRequests: {
            label: 'Food req.',
        },
        partnerName: {
            label: 'P Naam',
        },
        partnerWillAttend: {
            label: 'P Aanwezig',
        },
        partnerWillAttendDoorMoment: {
            label: 'P Deurmoment',
        },
        partnerWillAttendCivilFidelity: {
            label: ' PBurgerlijke trouw',
        },
        partnerWillAttendCeremony: {
            label: 'P Ceremonie',
        },
        partnerWillAttendReception: {
            label: 'P Receptie',
        },
        partnerWillAttendDinner: {
            label: 'P Diner',
        },
        partnerWillAttendParty: {
            label: 'P Party',
        },
        partnerSpecialFoodRequests: {
            label: 'P Food Req.',
        },
        staysOverForBreakfast: {
            label: 'Slapen',
        },
        otherComments: {
            label: 'Comments',
        },
    };

    return (
        <>
            <HTTitleBar label='Samenvatting' additionalRowClasses='mt-2' />
            <HTManageTable data={summaryTableData} columns={summaryColumns} />

            <HTTitleBar label='Aanwezigen' additionalRowClasses='mt-2' />
            <HTManageTable data={willAttendAttendees} columns={columns} />

            <HTTitleBar label='Zullen niet aanwezig zijn' additionalRowClasses='mt-2' />
            <HTManageTable data={wontAttendAttendees} columns={columns} />

            <HTTitleBar label='Moeten nog antwoorden' additionalRowClasses='mt-2' />
            <HTManageTable data={notRespondedYet} columns={columns} />
        </>
    );
};

export default Summary;
