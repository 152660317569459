const InfoElement = ({ name, icon, time, color }) => {
    const iconClassName = 'fa-thin text-white fs-a-1 ' + icon;
    const containerClassName = 'h-100 ' + color;

    return (
        <div className='arne-col h-100 font-weight-bold second-row mb-2 mb-md-0'>
            <div className={containerClassName}>
                <div className='info-element-icon-container d-flex justify-content-center align-items-end'>
                    <i className={iconClassName} />
                </div>
                <div className='info-element-half-half text-white d-flex justify-content-center align-items-start fs-4 pt-4 text-uppercase text-center'>{name}</div>
                <div className='info-element-half-half text-white d-flex justify-content-center align-items-center fs-4'>{time}</div>
            </div>
        </div>
    );
};

export default InfoElement;
