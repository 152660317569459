import { HTManageTable, HTTitleBar } from '@hanssens/ht-gui';

const Party = ({ attendees }) => {
    const partyPeople = attendees.filter((a) => a.willAttend && (a.willAttendDinner || a.partnerWillAttendDinner));

    const names = partyPeople.reduce((acc, cur) => {
        if (cur.willAttend && cur.willAttendDinner) {
            acc.push({ name: cur.name, note: cur.specialFoodRequests });
        }
        if (cur.partnerWillAttend && cur.partnerWillAttendDinner) {
            acc.push({ name: cur.partnerName, note: cur.partnerSpecialFoodRequests });
        }

        return acc;
    }, []);

    const columns = {
        name: {
            label: 'Naam',
        },
        note: {
            label: 'Opmerking eten',
        },
    };

    const dinnerData = [{ name: 'Aantal', value: names.length }];
    const dinnerDataColumns = { name: { label: 'Naam' }, value: { label: '' } };

    return (
        <>
            <HTTitleBar label='Overzicht' additionalRowClasses='mt-2' colour='bg-info' />
            <HTManageTable
                data={dinnerData}
                columns={dinnerDataColumns}
                search={{
                    type: 'hidden',
                }}
            />
            <HTTitleBar label='Eters + partiers' additionalRowClasses='mt-2' colour='bg-info' />
            <HTManageTable data={names} columns={columns} />
        </>
    );
};

export default Party;
