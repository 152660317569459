const InfoElement2 = ({ name, icon, time, color }) => {
    const iconClassName = 'fa-thin text-white fs-a-1 ' + icon;
    const containerClassName = 'h-100 d-flex ' + color;

    return (
        <div className='arne-col-2 font-weight-bold second-row mb-2 mb-md-0'>
            <div className={containerClassName}>
                <div className='w-50 h-100'>
                    <div className='info-element-icon-container d-flex justify-content-center align-items-center h-100'>
                        <i className={iconClassName} />
                    </div>
                </div>
                <div className='w-50 h-100'>
                    <div className='info-element-icon-container d-flex justify-content-center align-items-center h-100 fs-1 text-white d-flex flex-column text-uppercase'>
                        <span>{name}</span>
                        <span>{time}</span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default InfoElement2;
