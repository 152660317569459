import { HTInput, HTCheckbox, HTSwitch, HTButton } from '@hanssens/ht-gui';
import { Tooltip } from '@mui/material';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { useState } from 'react';

import AttendeeApi from '../apis/AttendeeApi';
import Artists from '../components/rsvp/artists';

const debounceFunction = () => {
    return;
};

const debounce = AwesomeDebouncePromise(debounceFunction, 500);

const Rsvp = () => {
    const [name, setName] = useState('');
    const [searchResult, setSearchResult] = useState(false);
    const [attendee, setAttendee] = useState({});
    const [partnerWillAttendTheSame, setPartnerWillAttendTheSame] = useState(true);
    const [requestSend, setRequestSend] = useState(false);

    const handleNameChanged = async (e, value) => {
        setName(value);
        await debounce();

        AttendeeApi.get('api/feat?name=' + value)
            .then((resp) => {
                setAttendee(resp.data);

                if (
                    resp.data.willAttendDoorMoment === resp.data.partnerWillAttendDoorMoment &&
                    resp.data.willAttendCivilFidelity === resp.data.partnerWillAttendCivilFidelity &&
                    resp.data.willAttendCeremony === resp.data.partnerWillAttendCeremony &&
                    resp.data.willAttendReception === resp.data.partnerWillAttendReception &&
                    resp.data.willAttendDinner === resp.data.partnerWillAttendDinner &&
                    resp.data.willAttendParty === resp.data.partnerWillAttendParty
                ) {
                    setPartnerWillAttendTheSame(true);
                } else {
                    setPartnerWillAttendTheSame(false);
                }
            })
            .finally(() => {
                setSearchResult(true);
            });
    };

    const handleAttendeeChanged = (prop, value) => {
        setAttendee((currentAttendee) => {
            return {
                ...currentAttendee,
                [prop]: value,
            };
        });

        if (partnerWillAttendTheSame && prop === 'willAttendDoorMoment') {
            handleAttendeeChanged('partnerWillAttendDoorMoment', value);
        }
        if (partnerWillAttendTheSame && prop === 'willAttendCivilFidelity') {
            handleAttendeeChanged('partnerWillAttendCivilFidelity', value);
        }
        if (partnerWillAttendTheSame && prop === 'willAttendCeremony') {
            handleAttendeeChanged('partnerWillAttendCeremony', value);
        }
        if (partnerWillAttendTheSame && prop === 'willAttendReception') {
            handleAttendeeChanged('partnerWillAttendReception', value);
        }
        if (partnerWillAttendTheSame && prop === 'willAttendDinner') {
            handleAttendeeChanged('partnerWillAttendDinner', value);
        }
        if (partnerWillAttendTheSame && prop === 'willAttendParty') {
            handleAttendeeChanged('partnerWillAttendParty', value);
        }
    };

    const handlePartnerWillAttendTheSameChanged = (value) => {
        setPartnerWillAttendTheSame(value);
    };

    const handleSubmitClicked = (e) => {
        e.preventDefault();

        AttendeeApi.post('api/feat', attendee).then(() => {
            setRequestSend(true);
        });
    };

    /****************************************************
     * Render
     ****************************************************/
    let saveButtonName = 'We kunnen er jammergenoeg niet bij zijn.';

    const createWillAttendContent = () => {
        let doormomentContent = null;
        let civilFidelityContent = null;
        let ceremonyContent = null;
        let receptionContent = null;
        let dinnerContent = null;
        let partyContent = null;

        if (attendee?.isInvitedForDoorMoment) {
            doormomentContent = (
                <HTCheckbox
                    label='Deurmoment'
                    onChange={(e, value) => handleAttendeeChanged('willAttendDoorMoment', value)}
                    value={attendee?.willAttendDoorMoment ?? false}
                    disabled={!attendee?.isInvitedForDoorMoment}
                />
            );
        } else {
            doormomentContent = (
                <Tooltip title='Jammergenoeg kunnen we niet iedereen voor de volledige dag uitnodigen' placement='top-start'>
                    <div>
                        <HTCheckbox
                            label='Deurmoment'
                            onChange={(e, value) => handleAttendeeChanged('willAttendDoorMoment', value)}
                            value={attendee?.willAttendDoorMoment ?? false}
                            disabled={!attendee?.isInvitedForDoorMoment}
                        />
                    </div>
                </Tooltip>
            );
        }

        if (attendee?.isInvitedForCivilFidelity) {
            civilFidelityContent = (
                <HTCheckbox
                    label='Burgerlijke trouw'
                    onChange={(e, value) => handleAttendeeChanged('willAttendCivilFidelity', value)}
                    value={attendee?.willAttendCivilFidelity ?? false}
                    disabled={!attendee?.isInvitedForCivilFidelity}
                />
            );
        } else {
            civilFidelityContent = (
                <Tooltip title='Jammergenoeg kunnen we niet iedereen voor de volledige dag uitnodigen' placement='top-start'>
                    <div>
                        <HTCheckbox
                            label='Burgerlijke trouw'
                            onChange={(e, value) => handleAttendeeChanged('willAttendCivilFidelity', value)}
                            value={attendee?.willAttendCivilFidelity ?? false}
                            disabled={!attendee?.isInvitedForCivilFidelity}
                        />
                    </div>
                </Tooltip>
            );
        }

        if (attendee?.isInvitedForCeremony) {
            ceremonyContent = (
                <HTCheckbox
                    label='Ceremonie'
                    onChange={(e, value) => handleAttendeeChanged('willAttendCeremony', value)}
                    value={attendee?.willAttendCeremony ?? false}
                    disabled={!attendee?.isInvitedForCeremony}
                />
            );
        } else {
            ceremonyContent = (
                <Tooltip title='Jammergenoeg kunnen we niet iedereen voor de volledige dag uitnodigen' placement='top-start'>
                    <div>
                        <HTCheckbox
                            label='Ceremonie'
                            onChange={(e, value) => handleAttendeeChanged('willAttendCeremony', value)}
                            value={attendee?.willAttendCeremony ?? false}
                            disabled={!attendee?.isInvitedForCeremony}
                        />
                    </div>
                </Tooltip>
            );
        }

        if (attendee?.isInvitedForReception) {
            receptionContent = (
                <HTCheckbox
                    label='Receptie'
                    onChange={(e, value) => handleAttendeeChanged('willAttendReception', value)}
                    value={attendee?.willAttendReception ?? false}
                    disabled={!attendee?.isInvitedForReception}
                />
            );
        } else {
            receptionContent = (
                <Tooltip title='Jammergenoeg kunnen we niet iedereen voor de volledige dag uitnodigen' placement='top-start'>
                    <div>
                        <HTCheckbox
                            label='Receptie'
                            onChange={(e, value) => handleAttendeeChanged('willAttendReception', value)}
                            value={attendee?.willAttendReception ?? false}
                            disabled={!attendee?.isInvitedForReception}
                        />
                    </div>
                </Tooltip>
            );
        }

        if (attendee?.isInvitedForDinner) {
            dinnerContent = (
                <HTCheckbox
                    label='Diner'
                    onChange={(e, value) => handleAttendeeChanged('willAttendDinner', value)}
                    value={attendee?.willAttendDinner ?? false}
                    disabled={!attendee?.isInvitedForDinner}
                />
            );
        } else {
            dinnerContent = (
                <Tooltip title='Jammergenoeg kunnen we niet iedereen voor de volledige dag uitnodigen' placement='top-start'>
                    <div>
                        <HTCheckbox
                            label='Diner'
                            onChange={(e, value) => handleAttendeeChanged('willAttendDinner', value)}
                            value={attendee?.willAttendDinner ?? false}
                            disabled={!attendee?.isInvitedForDinner}
                        />
                    </div>
                </Tooltip>
            );
        }

        if (attendee?.isInvitedForParty) {
            partyContent = (
                <HTCheckbox label='Feest' onChange={(e, value) => handleAttendeeChanged('willAttendParty', value)} value={attendee?.willAttendParty ?? false} disabled={!attendee?.isInvitedForParty} />
            );
        } else {
            partyContent = (
                <Tooltip title='Jammergenoeg kunnen we niet iedereen voor de volledige dag uitnodigen' placement='bottom-start'>
                    <div>
                        <HTCheckbox
                            label='Feest'
                            onChange={(e, value) => handleAttendeeChanged('willAttendParty', value)}
                            value={attendee?.willAttendParty ?? false}
                            disabled={!attendee?.isInvitedForParty}
                        />
                    </div>
                </Tooltip>
            );
        }

        return (
            <>
                {doormomentContent}
                {civilFidelityContent}
                {ceremonyContent}
                {receptionContent}
                {dinnerContent}
                {partyContent}
            </>
        );
    };

    const createPartnerWillAttendContent = () => {
        let doormomentContent = null;
        let civilFidelityContent = null;
        let ceremonyContent = null;
        let receptionContent = null;
        let dinnerContent = null;
        let partyContent = null;

        if (attendee?.isInvitedForDoorMoment) {
            doormomentContent = (
                <HTCheckbox
                    label='Deurmoment'
                    onChange={(e, value) => handleAttendeeChanged('partnerWillAttendDoorMoment', value)}
                    value={attendee?.partnerWillAttendDoorMoment ?? false}
                    disabled={!attendee?.isInvitedForDoorMoment}
                />
            );
        } else {
            doormomentContent = (
                <Tooltip title='Jammergenoeg kunnen we niet iedereen voor de volledige dag uitnodigen' placement='top-start'>
                    <div>
                        <HTCheckbox
                            label='Deurmoment'
                            onChange={(e, value) => handleAttendeeChanged('partnerWillAttendDoorMoment', value)}
                            value={attendee?.partnerWillAttendDoorMoment ?? false}
                            disabled={!attendee?.isInvitedForDoorMoment}
                        />
                    </div>
                </Tooltip>
            );
        }

        if (attendee?.isInvitedForCivilFidelity) {
            civilFidelityContent = (
                <HTCheckbox
                    label='Burgerlijke trouw'
                    onChange={(e, value) => handleAttendeeChanged('partnerWillAttendCivilFidelity', value)}
                    value={attendee?.partnerWillAttendCivilFidelity ?? false}
                    disabled={!attendee?.isInvitedForCivilFidelity}
                />
            );
        } else {
            civilFidelityContent = (
                <Tooltip title='Jammergenoeg kunnen we niet iedereen voor de volledige dag uitnodigen' placement='top-start'>
                    <div>
                        <HTCheckbox
                            label='Burgerlijke trouw'
                            onChange={(e, value) => handleAttendeeChanged('partnerWillAttendCivilFidelity', value)}
                            value={attendee?.partnerWillAttendCivilFidelity ?? false}
                            disabled={!attendee?.isInvitedForCivilFidelity}
                        />
                    </div>
                </Tooltip>
            );
        }

        if (attendee?.isInvitedForCeremony) {
            ceremonyContent = (
                <HTCheckbox
                    label='Ceremonie'
                    onChange={(e, value) => handleAttendeeChanged('partnerWillAttendCeremony', value)}
                    value={attendee?.partnerWillAttendCeremony ?? false}
                    disabled={!attendee?.isInvitedForCeremony}
                />
            );
        } else {
            ceremonyContent = (
                <Tooltip title='Jammergenoeg kunnen we niet iedereen voor de volledige dag uitnodigen' placement='top-start'>
                    <div>
                        <HTCheckbox
                            label='Ceremonie'
                            onChange={(e, value) => handleAttendeeChanged('partnerWillAttendCeremony', value)}
                            value={attendee?.partnerWillAttendCeremony ?? false}
                            disabled={!attendee?.isInvitedForCeremony}
                        />
                    </div>
                </Tooltip>
            );
        }

        if (attendee?.isInvitedForReception) {
            receptionContent = (
                <HTCheckbox
                    label='Receptie'
                    onChange={(e, value) => handleAttendeeChanged('partnerWillAttendReception', value)}
                    value={attendee?.partnerWillAttendReception ?? false}
                    disabled={!attendee?.isInvitedForReception}
                />
            );
        } else {
            receptionContent = (
                <Tooltip title='Jammergenoeg kunnen we niet iedereen voor de volledige dag uitnodigen' placement='top-start'>
                    <div>
                        <HTCheckbox
                            label='Receptie'
                            onChange={(e, value) => handleAttendeeChanged('partnerWillAttendReception', value)}
                            value={attendee?.partnerWillAttendReception ?? false}
                            disabled={!attendee?.isInvitedForReception}
                        />
                    </div>
                </Tooltip>
            );
        }

        if (attendee?.isInvitedForDinner) {
            dinnerContent = (
                <HTCheckbox
                    label='Diner'
                    onChange={(e, value) => handleAttendeeChanged('partnerWillAttendDinner', value)}
                    value={attendee?.partnerWillAttendDinner ?? false}
                    disabled={!attendee?.isInvitedForDinner}
                />
            );
        } else {
            dinnerContent = (
                <Tooltip title='Jammergenoeg kunnen we niet iedereen voor de volledige dag uitnodigen' placement='top-start'>
                    <div>
                        <HTCheckbox
                            label='Diner'
                            onChange={(e, value) => handleAttendeeChanged('partnerWillAttendDinner', value)}
                            value={attendee?.partnerWillAttendDinner ?? false}
                            disabled={!attendee?.isInvitedForDinner}
                        />
                    </div>
                </Tooltip>
            );
        }

        if (attendee?.isInvitedForParty) {
            partyContent = (
                <HTCheckbox
                    label='Feest'
                    onChange={(e, value) => handleAttendeeChanged('partnerWillAttendParty', value)}
                    value={attendee?.partnerWillAttendParty ?? false}
                    disabled={!attendee?.isInvitedForParty}
                />
            );
        } else {
            partyContent = (
                <Tooltip title='Jammergenoeg kunnen we niet iedereen voor de volledige dag uitnodigen' placement='bottom-start'>
                    <div>
                        <HTCheckbox
                            label='Feest'
                            onChange={(e, value) => handleAttendeeChanged('partnerWillAttendParty', value)}
                            value={attendee?.partnerWillAttendParty ?? false}
                            disabled={!attendee?.isInvitedForParty}
                        />
                    </div>
                </Tooltip>
            );
        }

        return (
            <>
                {doormomentContent}
                {civilFidelityContent}
                {ceremonyContent}
                {receptionContent}
                {dinnerContent}
                {partyContent}
            </>
        );
    };

    let attendenceContent = null;
    let willAttendContent = null;
    let buttonContent = null;

    if (attendee?.id) {
        attendenceContent = (
            <>
                <div className='mt-2'>
                    <b>Aanwezigheid</b>
                </div>
                <HTSwitch label='Ik wil er zeker bij zijn!' onChange={(e, value) => handleAttendeeChanged('willAttend', value)} value={attendee?.willAttend ?? false} />
            </>
        );

        if (attendee?.willAttend) {
            let partnerContent = null;

            if (attendee?.partnerWillAttend) {
                partnerContent = <HTSwitch label='Partner zal voor hetzelfde aanwezig zijn.' onChange={(e, value) => handlePartnerWillAttendTheSameChanged(value)} value={partnerWillAttendTheSame} />;

                if (!partnerWillAttendTheSame) {
                    partnerContent = (
                        <>
                            {partnerContent}

                            <div className='pt-2'>Partner zal wel voor volgende momenten aanwezig zijn:</div>
                            {createPartnerWillAttendContent()}
                        </>
                    );
                }

                partnerContent = (
                    <>
                        {partnerContent}
                        <div className='mt-2 mb-3'>
                            <b>Allergieën partner</b>
                        </div>
                        <div>
                            <HTInput
                                label='Heeft je partner allergieën, mag hij/zij iets niet eten? Laat het ons weten!'
                                multiline
                                onChange={(e, value) => handleAttendeeChanged('partnerSpecialFoodRequests', value)}
                                rows={4}
                                value={attendee.partnerSpecialFoodRequests}
                            />
                        </div>
                    </>
                );
            }

            willAttendContent = (
                <>
                    <div className='mt-3'>Ik ben graag op volgende momenten aanwezig:</div>
                    {createWillAttendContent()}

                    <div className='mt-2 mb-3'>
                        <b>Allergieën</b>
                    </div>
                    <div>
                        <HTInput
                            label='Heb je allergieën, mag je iets niet eten? Laat het ons weten!'
                            multiline
                            onChange={(e, value) => handleAttendeeChanged('specialFoodRequests', value)}
                            rows={4}
                            value={attendee.specialFoodRequests}
                        />
                    </div>
                    <div className='mt-2'>
                        <div className='my-2'>
                            <b>Wederhelft</b>
                        </div>
                        <HTSwitch label='Ik breng mijn wederhelft mee' onChange={(e, value) => handleAttendeeChanged('partnerWillAttend', value)} value={attendee?.partnerWillAttend ?? false} />
                        {partnerContent}
                    </div>

                    <div className='mt-2'>
                        <div className='my-2'>
                            <b>Overnachten</b>
                            <div className='alert alert-primary' role='alert'>
                                We hebben een afspraak met het Leopold Hotel in Oudenaarde waardoor we korting kunnen aanbieden voor de mensen die graag blijven overnachten met ontbijt. Voor een
                                single kamer komt de prijs op €105, voor een double kamer komt dit op €115. Boeken kan via volgende link:
                                <a
                                    href='https://reservations.prem-hospitality.com/bookings/specials/speciaal-tarief-----huwelijksfeest-fanja-thoelen--and--arne-claerebout'
                                    target='_blank'
                                    rel='noreferrer'>
                                    Hotel boeken
                                </a>
                            </div>
                        </div>
                        <HTSwitch label='Ik blijf graag overnachten' onChange={(e, value) => handleAttendeeChanged('staysOverForBreakfast', value)} value={attendee?.staysOverForBreakfast ?? false} />
                    </div>

                    <div className='mt-2'>
                        <div className='my-2'>
                            <b>Andere info</b>
                            <HTInput
                                label='Wil je ons nog iets anders laten weten? Dit is het moment :)'
                                className='mt-3'
                                multiline
                                onChange={(e, value) => handleAttendeeChanged('otherComments', value)}
                                rows={4}
                                value={attendee.otherComments}
                            />
                        </div>
                    </div>
                </>
            );

            saveButtonName = 'Aanwezigheid bevestigen';
        }

        buttonContent = (
            <div className='my-2'>
                <HTButton onClick={handleSubmitClicked} size='large'>
                    {saveButtonName}
                </HTButton>
            </div>
        );
    }

    let fullContent = null;

    console.log('req', requestSend, attendee.willAttend);

    if (requestSend && attendee.willAttend) {
        fullContent = (
            <div className='alert alert-success' role='alert'>
                Bedankt om ons alle informatie te bezorgen! We kijken er naar uit om jullie op 9 juli te verwelkomen. <br />
                {attendee.staysOverForBreakfast ? (
                    <>
                        <b>Vergeet zeker je kamer ook niet bij het hotel te reserveren: </b>
                        <a href='https://reservations.prem-hospitality.com/bookings/specials/speciaal-tarief-----huwelijksfeest-fanja-thoelen--and--arne-claerebout' target='_blank' rel='noreferrer'>
                            Hotel boeken
                        </a>
                    </>
                ) : (
                    ''
                )}
            </div>
        );
    } else if (requestSend && !attendee.willAttend) {
        fullContent = (
            <div className='alert alert-success' role='alert'>
                Bedankt om te laten weten dat jullie er jammergenoeg niet bij kunnen zijn. We hopen het later nog eens met jullie te kunnen vieren!
            </div>
        );
    } else {
        console.log(searchResult && attendee?.id === null, searchResult, attendee.id);
        fullContent = (
            <>
                <div className='alert alert-primary' role='alert'>
                    We willen graag weten of jullie er bij kunnen zijn. Gelieve hieronder jouw naam in te geven en de verdere stappen te volgen. Vergeet niet te scrollen. <br />
                    Indien je reeds antwoordde, kan je altijd je naam nog eens ingeven om de info na te kijken.
                </div>
                <HTInput
                    className='pb-3 mt-2'
                    label='Gelieve je naam op te geven'
                    value={name}
                    onChange={handleNameChanged}
                    error={searchResult && attendee?.id === undefined}
                    helperText={
                        searchResult && attendee?.id === undefined
                            ? 'Deze naam werd niet gevonden in de database. Gelieve uw naam en voornaam samen in te geven. Probeer eventueel eens de naam van uw partner.'
                            : ''
                    }
                />
                {attendenceContent}
                {willAttendContent}
                {buttonContent}
            </>
        );
    }

    return (
        <div className='row h-100'>
            <div className='col-12 col-md-6 h-100 mb-2'>
                <div className='rsvp-container my-2 rsvp-container-height overflow-hidden'>
                    <div className='rsvp-title pb-2 fs-4 ps-4'>Aanwezigheid bevestigen</div>
                    <div className='rsvp-container-height-2 ht-gui-scrollable-y'>
                        <div className='p-2 p-md-4'>{fullContent}</div>
                    </div>
                </div>
            </div>
            <div className='col-12 col-md-6 mb-2'>
                <Artists />
            </div>
        </div>
    );
};

export default Rsvp;
