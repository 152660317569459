import WerchterBg from '../../assets/images/werchter-bg.png';

const LetUsKnow = ({ onRsvpClicked }) => {
    return (
        <div className='arne-col-2 first-row mb-2 mb-md-0'>
            <div className='first-row-half clickable overflow-hidden pb-2' onClick={onRsvpClicked}>
                <div className='w-100 h-100' style={{ backgroundImage: 'url(' + WerchterBg + ')', backgroundSize: 'cover', backgroundPositionX: 'right' }}>
                    <div className='w-100 h-100 d-flex justify-content-center align-items-start flex-column'>
                        <span className='text-white fs-1 text-uppercase ms-4 font-weight-bold'>Laat nu</span>
                        <span className='text-white fs-1 text-uppercase ms-4 font-weight-bold'>iets weten!</span>
                    </div>
                </div>
            </div>
            <div className='first-row-half bg-blue p-4 d-flex flex-column'>
                <span>
                    Tickets voor zaterdag 9 juli kunnen nu worden gereserveerd. Haast je en laat snel iets weten voor <b>19 juni</b>!
                </span>
                <span className='text-white pt-2'>#fanjaenarnetrouwen</span>
            </div>
        </div>
    );
};

export default LetUsKnow;
