import Arne from '../../assets/images/arne.png';
import Fanja from '../../assets/images/fanja3.png';
import Artist from './artist';

const Artists = () => {
    return (
        <>
            <div className='row mt-2'>
                <div className='col-12 col-md-6 overflow-hidden position-relative mb-2 second-row'>
                    <div className='h-100 w-100' style={{ backgroundImage: 'url(' + Fanja + ')', backgroundSize: 'cover', backgroundPosition: '50% 50%' }}></div>
                    <div className='artist-name text-white fs-4 font-weight-bold text-uppercase'>Fanja Thoelen</div>
                    <div className='artist-function text-white font-weight-bold'>Bruid</div>
                    <div className='artist-info text-white font-weight-bold text-uppercase bg-purple p-2'>0471 31 37 28</div>
                </div>
                <div className='col-12 col-md-6 overflow-hidden position-relative mb-2 second-row'>
                    <div className='h-100 w-100' style={{ backgroundImage: 'url(' + Arne + ')', backgroundSize: 'cover', backgroundPosition: '50% 50%' }}></div>
                    <div className='artist-name text-white fs-4 font-weight-bold text-uppercase'>Arne Claerebout</div>
                    <div className='artist-function text-white font-weight-bold'>Bruidegom</div>
                    <div className='artist-info text-white font-weight-bold text-uppercase bg-purple p-2'>0497 64 09 87</div>
                </div>
            </div>
            <div className='row'>
                <Artist name='Veerle Van Hoofstat' func='Ceremoniespreekster' info='0496 14 50 11' color='bg-green' icon='fa-microphone-lines' />
                <Artist name='Andy De Doncker' func='Zaalverantwoordelijke' info='055 31 65 25' color='bg-blue' icon='fa-user-group-crown' />
                <Artist name='Cartouche' func='DJ' info='0497 41 02 88' color='bg-orange' icon='fa-turntable' />
            </div>
            <div className='row'>
                <Artist name='Huis Mulle de Terschueren' func='Burgerlijke trouw' info='Ieperstraat 46, 8700 Tielt' color='bg-red' icon='fa-gavel' />
                <Artist name='Salons Mantovani' func='Ceremonie, receptie en feest' info='Doorn 1, 9700 Oudenaarde' color='bg-green-light' icon='fa-face-party' />
                <Artist name='Leopold Hotel' func='Hotel' info='De Ham 14, 9700 Oudenaarde' color='bg-green' icon='fa-hotel' />
            </div>
        </>
    );
};

export default Artists;
