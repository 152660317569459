import ArneFanja2 from '../../assets/images/arne-fanja-2.png';

const ThirdRow = () => {
    return (
        <div className='row mt-2'>
            <div className='arne-col-4 third-row mb-2 mb-md-0'>
                <div className='w-100 h-100' style={{ backgroundImage: 'url(' + ArneFanja2 + ')', backgroundSize: 'cover', backgroundPosition: '50% 50%' }}></div>
            </div>
            <div className='arne-col-4 third-row mb-2 mb-md-0'>
                <div className='bg-red h-100 d-flex flex align-items-center'>
                    <div className='bg-red-dark mx-4 text-white fs-2 font-weight-bold px-4 py-2'>Feesten tot in de vroege uurtjes</div>
                </div>
            </div>
        </div>
    );
};

export default ThirdRow;
